import React, { useEffect, useState } from "react"
import { css } from "@emotion/core"
import { connect, useDispatch } from "react-redux"
import { navigate } from "gatsby"
import uuid from "react-uuid"

import {
  LayoutQuoting,
  Title,
  SEO,
  SectionCenter,
  SecondaryButton,
  PrimaryButton,
  TravellersCard,
  AddPerson,
  GoBack,
} from "../components"
import { getCurrentPath, setTravellers, getConditions } from "../redux/actions"
import {
  setTravellersPayload,
  validTravelPlan,
  validTravellers,
} from "../utils/helpers"

const GetQuoteTravelers = ({
  location,
  getCurrentPath,
  travellers,
  travelPlan,
  setTravellers,
  canProceedFromUI,
}) => {
  const [continueOK, setContinueOK] = useState(false)
  const [isValidTravelPlan, setIsValidTravelPlan] = useState(true)
  const dependents = travellers.dependents
  const spouseState = Object.keys(travellers.spouse).length
  const dispatch = useDispatch()

  const onToggleAddSpouse = () => {
    if (spouseState <= 0) {
      setTravellers({
        spouse: {
          firstName: "",
          lastName: "",
          dob: null,
          gender: null,
        },
      })
    } else {
      setTravellers({
        spouse: {},
      })
    }
  }
  const onAddDependents = () => {
    setTravellers({
      dependents: dependents.concat([
        {
          firstName: "",
          lastName: "",
          dob: "",
          gender: "",
          id: uuid(),
        },
      ]),
    })
  }

  const onCanceldependents = id => {
    setTravellers({ dependents: dependents.filter(dep => dep.id !== id) })
  }

  useEffect(() => {
    getCurrentPath(location.pathname)
    setIsValidTravelPlan(validTravelPlan(travelPlan))
  }, [getCurrentPath, location.pathname, travelPlan])

  useEffect(() => {
    setContinueOK(validTravellers(travellers))
  }, [travellers])

  const onProceedToNextPage = () => {
    const travellerPayload = setTravellersPayload(travellers)
    dispatch(getConditions(travellerPayload))

    navigate("/get-quote-policies")
  }

  const onGoBackToPrevPage = () => {
    navigate("/get-quote-details")
  }

  return (
    <LayoutQuoting>
      <SEO title="Get my quote - travellers" />
      <section
        css={css`
          padding: 5rem 0;
        `}
      >
        {isValidTravelPlan ? (
          <>
            <Title
              title="who are travelling?"
              fontColor="var(--clr-primary-300)"
            />
            <SectionCenter>
              <div
                css={css`
                  width: 100%;
                  display: grid;
                  row-gap: 2.5rem;
                  margin: 0 auto 4rem auto;
                `}
              >
                <TravellersCard role="policyHolder" caption="policy holder" />
                {spouseState > 0 ? (
                  <TravellersCard
                    role="spouse"
                    caption="spouse"
                    onCloseCard={onToggleAddSpouse}
                  />
                ) : (
                  <AddPerson role="spouse" onClick={onToggleAddSpouse} />
                )}
                {dependents.map(dep => (
                  <TravellersCard
                    role="dependents"
                    caption={`dependent`}
                    onCloseCard={() => onCanceldependents(dep.id)}
                    key={dep.id}
                    id={dep.id}
                  />
                ))}
                <AddPerson role="dependents" onClick={onAddDependents} />
              </div>
              <div
                css={css`
                  display: flex;
                  justify-content: center;
                `}
              >
                <PrimaryButton
                  textSize="0.875rem"
                  textTransform="capitalize"
                  maxWidth="none"
                  margin="0 4rem 0 0"
                  disabled={!continueOK || !canProceedFromUI}
                  onClick={onProceedToNextPage}
                >
                  Continue
                </PrimaryButton>
                <SecondaryButton
                  buttonColor="var(--clr-neutral-400)"
                  textSize="0.875rem"
                  margin="none"
                  onClick={onGoBackToPrevPage}
                >
                  Go back
                </SecondaryButton>
              </div>
            </SectionCenter>
          </>
        ) : (
          <GoBack backToLink="/get-quote-details" notFilledOut="travel plan" />
        )}
      </section>
    </LayoutQuoting>
  )
}

const mapStateToProps = ({ travellers, canProceedFromUI, travelPlan }) => ({
  travellers,
  canProceedFromUI,
  travelPlan,
})

export default connect(mapStateToProps, {
  getCurrentPath,
  setTravellers,
})(GetQuoteTravelers)
